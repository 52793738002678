blockquote {
  --font-size: #{px-to-rem(28px)};

  margin-bottom: 0;
  font-family: $headings-font-family;
  font-size: var(--font-size);

  @include media-breakpoint-up(xl) {
    --font-size: #{px-to-rem(54px)};
  }

  .slider-wrapper & {
    --font-size: #{px-to-rem(20px)};

    @include media-breakpoint-up(xl) {
      --font-size: #{px-to-rem(28px)};
    }
  }

  p {
    &:first-child {
      &:before {
        content: '„';

        [lang='en-US'] & {
          content: '“';
        }

        [lang='es-ES'] & {
          content: '«';
        }
      }
    }

    &:last-of-type {
      &:after {
        content: '“';

        [lang='en-US'] & {
          content: '”';
        }

        [lang='es-ES'] & {
          content: '»';
        }
      }
    }
  }

  footer {
    font-size: px-to-rem(14px);

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }
  }

  cite {
    font-family: $font-family-base;
    font-style: normal;
  }
}
