.frame {
  &:last-child {
    --frame-margin-bottom: 0;
  }

  #page-header + #animation-wrapper > &:first-child {
    margin-top: 0;
  }

  & + .frame-type-tx_supi_button {
    --frame-margin-top: #{$grid-gutter-width * -1};

    + .frame {
      --frame-margin-top: 0;
    }

    @include media-breakpoint-up(md) {
      --frame-margin-top: #{$grid-gutter-width * -2};
    }

    @include media-breakpoint-up(xl) {
      --frame-margin-top: #{$grid-gutter-width * -3};
    }

    @include media-breakpoint-up(ultrawide) {
      --frame-margin-top: #{$grid-gutter-width * -4};
    }
  }

  .reduce-next-margin + & {
    margin-top: calc(var(--frame-margin-top) * 0.5);
  }

  margin-top: var(--frame-margin-top);
  margin-bottom: var(--frame-margin-bottom);

  &[class*='bg-']:has(+ [class*='bg-']) {
    --frame-margin-bottom: 0;

    & + [class*='bg-'] {
      --frame-margin-top: 0;
    }
  }
}
