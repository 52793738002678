#footer {
  @include media-breakpoint-up(md) {
    transform: translateY(var(--hero-text-position-y));
    will-change: transform;
  }
}

@media (prefers-reduced-motion: reduce) {
  #footer {
    --hero-text-position-y: 0 !important;
  }
}
