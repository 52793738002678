@use 'sass:math';

.product-groups {
  &__block {
    h3 {
      margin-bottom: $grid-gutter-width * 0.25;

      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width * 0.5;
      }
    }
  }

  &__link {
    font-family: $headings-font-family;
    font-size: px-to-rem(18px);
    padding: $grid-gutter-width * 0.25 0;
    text-decoration: none;
    color: __get($theme-colors, 'copytext');
    display: flex;
    align-items: center;

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(28px);
    }

    .icon {
      transform: rotate(-90deg);

      @include media-breakpoint-up(xl) {
        transform: rotate(-90deg) scale(1.5);
      }
    }
  }
}
