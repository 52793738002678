.container {
  &-fluid {
    max-width: map-get($container-max-widths, 'ultrawide');

    @include media-breakpoint-up(xl) {
      --bs-gutter-x: #{$grid-gutter-width * 2};
    }

    @include media-breakpoint-up(ultrawide) {
      --bs-gutter-x: #{$grid-gutter-width};
    }
  }
}
