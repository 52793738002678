.news {
  &-startpage {
    &__item {
      h3 {
        font-size: px-to-rem(18px);
        line-height: 1.5;
      }
    }
  }

  &-list__newspage {
    --gallery-columns: 1;
    grid-template-columns: repeat(var(--gallery-columns), 1fr);

    @include media-breakpoint-up(md) {
      --gallery-columns: 2;
    }

    @include media-breakpoint-up(xl) {
      --gallery-columns: 3;
    }

    .article {
      &-header__info {
        font-size: px-to-rem(16px);
      }
    }
  }

  &-detail {
    &__teaser {
      font-family: $headings-font-family;
      font-size: px-to-rem(24px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(32px);
      }

      line-height: 1.5;
    }
  }
}
