@import '../../../node_modules/glightbox/dist/css/glightbox';

.goverlay {
  background-color: __get($theme-colors, 'white');

  .glightbox-mobile & {
    background: __get($theme-colors, 'white');
  }

  .gslide-description {
    background-color: transparent;

    .gdesc-inner {
      padding-top: $grid-gutter-width * 0.5;

      .gslide-desc {
        color: $black;
      }
    }
  }
}

.glightbox-mobile .glightbox-container .gslide-description {
  flex: 0 0 auto;
  padding: 0;
}

.gcontainer {
  .gslide-media {
    box-shadow: none;
    @include media-breakpoint-down(lg) {
      img {
        max-height: 75vh;
      }
    }
  }

  .gslide-description {
    background: transparent !important;
    position: relative !important;
    flex: 0;

    .gdesc-inner {
      padding-top: $grid-gutter-width * 0.5;

      .gslide-desc {
        text-align: center;
        color: $gray-700;
      }
    }
  }

  .gbtn {
    &.gclose {
      position: absolute;
      opacity: 1;
      top: 30px;
      right: 30px;
      width: 48px;
      height: 48px;
      box-shadow: $box-shadow;
      background-color: transparent !important;
      @include media-breakpoint-up(xxl) {
        box-shadow: none;
      }

      svg {
        background-color: transparent;
        filter: brightness(0) saturate(0);
      }
    }

    &.gcount {
      position: absolute;
      top: 30px;
      left: 30px;
      @include media-breakpoint-up(xxl) {
        background-color: transparent !important;
      }
    }

    &.gnext,
    &.gprev {
      width: 48px;
      height: 48px;
      top: auto;
      bottom: 30px;
      box-shadow: $box-shadow;
      background-color: transparent !important;
      @include media-breakpoint-up(xxl) {
        box-shadow: none;
      }

      @include media-breakpoint-up(md) {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }

      svg {
        width: 24px;
        height: 24px;
        filter: brightness(0) saturate(0);
        align-self: center;
        justify-self: center;
      }

      &.disabled {
        display: none;
      }
    }
  }
}

.glightbox-closing {
  .gcount {
    opacity: 0;
  }
}

.glightbox-maps {
  .goverlay,
  .ginlined-content {
    background: __get($theme-colors, 'action-2');
  }

  .gslide {
    user-select: auto;
  }
}
