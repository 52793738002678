@mixin form-invalid-icon {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url($form-feedback-icon-invalid);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

@mixin form-invalid-icons-input {
  padding-right: calc((1.5em + 0.75rem) * 2);
  background-image: url($form-feedback-icon-invalid), url($form-input-select-bg-image);
  background-repeat: no-repeat;
  background-position: right calc((0.375em + 0.1875rem) * 4) center, right 16px center;
  background-size: calc(0.75em + 0.375rem), 16px 12px;
}

@mixin form-required-asterisk {
  content: '*';
  top: -0.5em;
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

.form {
  .field-h {
    position: absolute;
    margin: 0 0 0 -999em;
  }

  &-check {
    .radio-full-width & {
      display: block;
    }

    &-input {
      background-size: cover;

      &[type='radio'] {
        background: $form-check-radio-unchecked-bg-image no-repeat center;

        &:valid ~ .form-check-label,
        &:invalid ~ .form-check-label {
          color: __get($theme-colors, 'copytext');
        }

        &:checked {
          background: $form-check-radio-checked-bg-image no-repeat center;
          background-color: transparent;
        }

        &:focus {
          @include focus-ring-style;
        }
      }

      &[type='checkbox'] {
        background: $form-check-input-unchecked-bg-image no-repeat center;

        &:checked {
          background: $form-check-input-checked-bg-image no-repeat center;
        }

        &:focus {
          @include focus-ring-style;
        }

        ~ .form-check-label {
          color: __get($theme-colors, 'copytext');
        }

        &:required ~ .form-check-label:after {
          @include form-required-asterisk;
        }

        &:invalid ~ .form-check-label:after {
          color: __get($theme-colors, 'primary');
        }
      }
    }
  }

  &-control {
    line-height: $form-select-line-height;

    &:not(:placeholder-shown):invalid {
      @include form-invalid-icon;
    }

    &:required:invalid:focus:not(.search-form__input) {
      @include form-invalid-icon;
    }

    &:focus {
      @include focus-ring-style;
    }
  }

  &-control[type='date'] {
    line-height: $form-select-line-height;
  }

  &-select {
    &:focus {
      @include focus-ring-style;
    }
    &:invalid:required {
      @include form-invalid-icons-input;
    }
  }

  &-file {
    input {
      background-color: transparent !important;

      &::file-selector-button {
        @extend .btn;
        @extend .btn-outline-primary;
      }
    }
  }

  &-group-radio {
    margin-bottom: 0 !important;
    display: inline-flex;
    flex-direction: column;
    height: 100%;

    &:has(.form-check-inline) .form-group {
      display: flex;
      align-items: center;
      height: 100%;
    }

    legend {
      @extend .form-label;
      line-height: 1.5;
      font-family: $font-family-base;
    }

    .form-check-inline {
      margin-right: $grid-gutter-width;
    }

    &:invalid legend {
      color: $form-feedback-invalid-color;
    }

    &-question {
      legend {
        font-size: 1em;
        color: __get($theme-colors, 'copytext');

        &:after {
          @include form-required-asterisk;
        }
      }
    }
  }

  &-hide-spinner {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  small {
    display: inline-block;
    line-height: 1.5;
    margin-top: $grid-gutter-width * 0.125;
  }

  .invalid-feedback {
    color: __get($theme-colors, 'primary');
  }
}
