.gallery-wrapper-collection {
  gap: $grid-gutter-width * 1.5;

  a.lightbox {
    picture img {
      object-fit: scale-down;
    }

    &.svg {
      picture {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100px;
          @include media-breakpoint-up(sm) {
            width: 200px;
          }

          @include media-breakpoint-up(md) {
            width: 100px;
          }

          @include media-breakpoint-up(xl) {
            width: 150px;
          }
        }
      }
    }
  }
}
