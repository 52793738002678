@mixin focus-ring-style {
  outline: __get($theme-colors, 'primary') dashed 2px;
  box-shadow: none;
  outline-offset: 2px;
}

@mixin focus-ring {
  &:focus-visible {
    @include focus-ring-style;
  }

  @supports not selector(:focus) {
    &:focus {
      @include focus-ring-style;
    }
  }
}
