.frame-type-teasers_for_pages,
.frame-type-aejobs_list {
  .article-list {
    --gallery-columns: 1;
    grid-template-columns: repeat(var(--gallery-columns), 1fr);

    @include media-breakpoint-up(xl) {
      --gallery-columns: 3;
    }
    img {
      object-fit: cover;
    }
  }
}
