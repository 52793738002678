@include motion-safe {
  [data-animation] {
    will-change: transform;
    transition: all 0.5s $animation-method;

    &[data-animation='fadeIn'] {
      opacity: 0;

      &.animated {
        opacity: 1;
      }
    }

    &[data-animation='timeline'] {
      .timeline-item {
        &:after {
          opacity: 0;
          transition: all 1s $animation-method;
        }
        .timeline-item__content {
          transform: translateX(-75px);
          transition: all 1s $animation-method;
          opacity: 0;
        }

        .timeline-item__year {
          transform: translateY(-75px);
          transition: all 1s $animation-method;
          opacity: 0;
        }
      }
      &:nth-child(2n) {
        .timeline-item {
          .timeline-item__content {
            transform: translateX(75px);
          }
        }
      }

      &.animated {
        .timeline-item {
          &:after {
            opacity: 1;
          }
          .timeline-item__content {
            transform: translate(0, 0);
            opacity: 1;
          }

          .timeline-item__year {
            transform: translate(0, 0);
            opacity: 1;
          }
        }
      }
    }

    &[data-animation='slideInLeft'] {
      transform: translateX(-100%);
      opacity: 0;

      &.frame-type-theme_textimage {
        transform: translateX(0);

        .bodytext,
        figure {
          will-change: transform;
          transition: all 0.5s $animation-method;
        }

        .bodytext {
          transform: translateX(-20%);
        }

        figure {
          transform: translateY(-50%);
        }

        &.animated .bodytext,
        &.animated figure {
          transform: translateX(0) translateY(0);
          opacity: 1;
        }
      }

      &.animated {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &[data-animation='slideInRight'] {
      transform: translateX(100%);
      opacity: 0;

      &.frame-type-theme_textimage {
        transform: translateX(0);

        .bodytext,
        figure {
          will-change: transform;
          transition: all 0.5s $animation-method;
        }

        .bodytext {
          transform: translateX(20%);
        }

        figure {
          transform: translateY(50%);
        }

        &.animated .bodytext,
        &.animated figure {
          transform: translateX(0) translateY(0);
          opacity: 1;
        }
      }

      &.animated {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .lightbox {
    picture {
      overflow: hidden;

      img {
        transform: scale(1);
        transition: all $animation-method-image-hover;
      }
    }

    &:hover {
      picture img {
        transform: scale(0.9);
      }
    }
  }

  .product-overview__wrapper picture {
    transition: all $animation-method-image-hover;
    &:hover {
      transform: scale(1.1);
    }
  }
}
