.frame-type-aejobs_show {
  .job-detail {
    &__info {
      font-family: $headings-font-family;
      font-size: px-to-rem(22px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(28px);
      }

      &-inner {
        --gallery-columns: 1;

        grid-template-columns: repeat(var(--gallery-columns), 1fr);
        gap: $grid-gutter-width;

        @include media-breakpoint-up(md) {
          --gallery-columns: 2;
        }

        @include media-breakpoint-up(xl) {
          --gallery-columns: 4;
          &.hasNoSalary {
            --gallery-columns: 3;
          }
        }
      }
    }

    &__text {
      font-size: px-to-rem(22px);
      font-family: $headings-font-family;

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(28px);
      }
    }

    &__related-pages-entry {
      flex: 1;
    }

    &__contact-info {
      font-family: $headings-font-family;
    }
  }
}
