.address-list {
  display: grid;
  grid-template-columns: repeat(var(--address-list-columns, 1), 1fr);

  @include media-breakpoint-up(md) {
    --address-list-columns: 2;
  }

  // dont show the button in the plumberfinder
  // but show the button in the inquiry process
  &__map-inquiry {
    body:not(:has(.frame-type-productfinder_inquiry)) & {
      display: none !important;
    }
  }

  &__item {
    .glightbox-maps & {
      flex-direction: column;
    }

    .frame-type-productfinder_advisor & {
      flex-direction: column;
    }

    .frame-type-productfinder_inquiry & {
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }

  &__buttons {
    button {
      svg {
        display: none;
      }
    }

    button:has(+ .address-list__inquiry) {
      display: flex;
      align-items: center;
      gap: $grid-gutter-width * 0.25;

      svg {
        display: inline;
      }

      &:active {
        --bs-btn-active-color: var(--bs-primary);
        --bs-btn-active-bg: transparent;
      }

      --bs-btn-padding-x: 0;
      --bs-btn-border-width: 0;
      --bs-btn-hover-bg: transparent;
      --bs-btn-hover-color: var(--bs-primary);
      text-align: start;

      + .address-list__inquiry {
        align-self: start;

        svg {
          display: none !important;
        }
      }
    }
  }

  // show the contact informations in the plumberfinder
  // but dont show the contact informations in the inquiry process
  &__contact {
    body:has(.frame-type-productfinder_inquiry) &,
    .frame-type-productfinder_inquiry & {
      display: none !important;
    }
  }

  &__mobile,
  &__fax,
  &__phone,
  &__mail,
  &__fqa,
  &__www {
    display: flex;
    gap: $grid-gutter-width * 0.5;
    word-break: break-word;
    align-items: baseline;

    &:before {
      flex-basis: $grid-gutter-width * 0.5;
    }
  }

  &__phone {
    &:before {
      content: 'T';
    }
  }

  &__mobile {
    &:before {
      content: 'M';
    }
  }

  &__mail {
    &:before {
      content: 'E';
    }
  }

  &__fax {
    &:before {
      content: 'F';
    }
  }

  &__fqa {
    &:before {
      content: 'A';
    }
  }

  &__www {
    &:before {
      content: 'W';
    }
  }
}
