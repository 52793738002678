main {
  #animation-wrapper {
    // prevent scrolling for animated elements from right
    .frame {
      overflow-x: hidden;
    }

    @include media-breakpoint-up(md) {
      transform: translateY(var(--hero-text-position-y));
      will-change: transform;
    }
  }

  transition: margin-top 200ms ease-in-out;
  margin-top: 52px;

  @include media-breakpoint-up(xl) {
    margin-top: 107px;

    body:not(.tmpl-Landingpage):has(#header.top) & {
      margin-top: 187px;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  main {
    --hero-text-position-y: 0 !important;
  }
}
