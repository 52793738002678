.frame-type-theme_teaser_text,
.teaser-text {
  h2 {
    @extend .byline;
    margin-bottom: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width * 0.75;
    }
  }

  p {
    font-family: $headings-font-family;
    font-size: px-to-rem(24px);

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(38px);
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(44px);
    }
  }
}
