.icon {
  &-direction {
    &-right {
      transform: rotate(-90deg);
    }
    &-left {
      transform: rotate(90deg);
    }
    &-mirrored {
      transform: rotate(180deg);
    }
  }

  &-caret {
    width: 15px;
    height: auto;
  }

  &-energy-efficiency {
    display: inline-block;
    width: 84px;
    height: auto;
  }

  &-paused__hero-video {
    height: auto;
    width: 48px;
    @include media-breakpoint-up(xl) {
      width: 64px;
    }
  }

  @each $size in $icon-sizes {
    &-#{$size}x#{$size} {
      aspect-ratio: 1/1;
      width: #{$size}px;
      height: auto;
    }
  }
}
