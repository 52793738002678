.logo {
  width: 60px;
  height: auto;

  #footer & {
    width: 89px;

    @include media-breakpoint-up(xl) {
      width: auto;
    }
  }

  svg {
    max-width: 100%;
  }

  @include media-breakpoint-up(xl) {
    width: auto;
  }
}
