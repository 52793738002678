.frame-type-theme_testimonials {
  &:not(:has(.slider-wrapper)) {
    h2 {
      @extend .byline;
      margin-bottom: $grid-gutter-width * 0.5;
    }
  }

  &:has(.slider-wrapper) {
    h2 {
      color: __get($theme-colors, 'copytext');
    }
  }
}
