// caption is the old table caption
caption {
  .bg-action-1 & {
    color: __get($theme-colors, 'dark');
  }
}

figcaption {
  font-size: px-to-rem(12px);
  color: $gray-700;
  text-align: center;
  margin-top: $grid-gutter-width * 0.25;

  figure.table-responsive & {
    margin: 0;
    padding-block: $grid-gutter-width * 0.5;
    color: __get($theme-colors, 'secondary');
    text-align: left;
    font-size: var(--bs-body-font-size);

    .bg-action-1 & {
      color: __get($theme-colors, 'dark');
    }
  }
}
