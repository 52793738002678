.btn {
  --bs-btn-hover-bg: #{$primary-500};
  --bs-btn-hover-border-color: #{$primary-500};

  &-sm {
    @include media-breakpoint-up(xl) {
      --bs-btn-padding-x: #{$grid-gutter-width * 0.5};
    }

    @include media-breakpoint-up(xxl) {
      --bs-btn-padding-x: #{$btn-padding-x-sm};
    }
  }

  &:not(.btn-primary):not(.btn-outline-primary) {
    #header & {
      --bs-btn-font-family: #{$font-family-base};
      --bs-btn-hover-color: #{__get($theme-colors, 'primary')};
      --bs-btn-hover-bg: transparent;
      --bs-btn-hover-border-color: transparent;

      &:focus,
      &.focus {
        background: __get($theme-colors, 'primary');
        color: __get($theme-colors, 'white');
      }
    }
  }

  &.active {
    #header #nav-section & {
      --bs-btn-font-family: #{$headings-font-family};
    }

    #header & {
      --bs-btn-active-border-color: transparent;

      background: __get($theme-colors, 'white');
      margin-top: $grid-gutter-width * -0.5;
      height: calc(100% + $grid-gutter-width);
      display: flex;
      align-items: center;
    }

    #header #nav-meta & {
      background: transparent;
      color: __get($theme-colors, 'primary');
      height: auto;
      margin: 0;

      &:focus,
      &.focus {
        background: __get($theme-colors, 'primary');
        color: __get($theme-colors, 'white');
      }
    }
  }

  &.btn-white {
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{__get($theme-colors, 'white')};
    --bs-btn-hover-color: #{__get($theme-colors, 'white')};
  }

  &.btn-link {
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;
    text-decoration: none;

    &:not(.collapsible__toggle) svg {
      transition: all 0.2s ease-in-out;
      transform: translateX(0);
    }

    &:not(.collapsible__toggle):hover {
      svg {
        transform: translateX(#{$grid-gutter-width * 0.125});
      }
    }
  }

  &-ico {
    position: relative;
    padding-left: $grid-gutter-width * 2;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: $grid-gutter-width;
      width: $grid-gutter-width * 0.75;
      height: 100%;
      background: var(--btn-icon);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__location:before {
      --btn-icon: url(#{$icon-location});
    }
  }

  .hero &,
  main &,
  .plumberfinder__result & {
    --bs-btn-font-size: px-to-rem(18px);
    --bs-btn-color: #{__get($theme-colors, 'primary')};
    --bs-btn-padding-x: #{$grid-gutter-width};
    --bs-btn-padding-y: #{$grid-gutter-width * 0.5};

    &.btn-primary {
      --bs-btn-color: #{__get($theme-colors, 'white')};
    }

    &.btn-outline-white {
      --bs-btn-color: #{$white};
      --bs-btn-hover-color: #{$primary};
      --bs-btn-hover-bg: #{$white};
      --bs-btn-hover-border-color: #{$white};
    }
  }

  #header #nav-main &,
  #header .search-wrapper & {
    --bs-btn-padding-x: #{__get($spacers, 'hg')};
    --bs-btn-padding-y: #{__get($spacers, 'sg')};

    display: inline-flex;

    &.active {
      --bs-btn-font-family: #{$headings-font-family};

      margin: 0;
      height: auto;
      background: __get($theme-colors, 'white');
      color: currentColor;
    }
  }

  #header #nav-main .navigation__meta-item & {
    &.active {
      color: __get($theme-colors, 'primary');
      &:focus {
        color: __get($theme-colors, 'white');
        background: __get($theme-colors, 'primary');
      }
    }
  }

  &-remove {
    &:hover {
      --bs-btn-hover-color: #{__get($theme-colors, 'white')};
    }
  }
}
