//overflow fix on search page
.tmpl-Search main#content {
  position: relative;
  z-index: 1;
  .frame {
    overflow: visible;
  }
}

// search form
.search {
  &-form {
    grid-template-columns: 1fr auto;
    grid-template-rows: 100% 0px;

    //open btn
    &__open {
      z-index: 10;
      grid-row: 1;
      grid-column: 1;
      justify-self: end;
      &:focus {
        background: transparent !important;
      }
    }

    //input, submit wrapper
    &__wrapper {
      grid-row: 1;
      grid-column: 1;
      grid-template-columns: auto auto;
      grid-template-rows: auto 0px;
      right: 0;
      opacity: 0;
      transition: 0.2s ease-in-out;

      //on open
      &.open {
        opacity: 1;

        input {
          width: $search-form-width * 0.8;
          padding: $input-padding-y $input-padding-x;

          @include media-breakpoint-up(sm) {
            width: $search-form-width;
          }
        }
      }
    }

    //form input, submit button
    &__input {
      width: 0;
      padding: 0;
      grid-column: 1;
      line-height: 1.2;
      transition: all 0.2s ease-in-out;
      &:focus {
        box-shadow: none;
      }
      &:focus-visible {
        outline: none !important;
      }
    }

    &__submit {
      grid-column: 2;
      &:focus {
        @extend .color-primary;
      }
    }

    //form in results page
    &__results {
      z-index: 2;
    }
  }
}

// auto suggest box
.tx-solr-suggest-box {
  @extend .shadow;
  background: __get($theme-colors, 'white');
  position: absolute;
  left: 0;
  width: auto;
  min-width: 250px;
  top: 100%;
  z-index: 1;

  ul:first-child {
    margin-bottom: 0;
    padding-bottom: $grid-gutter-width * 0.5;
    border-bottom: var(--bs-border-width) var(--bs-border-style) $gray-300;
  }

  ul:nth-child(2n) {
    margin-top: $grid-gutter-width * 0.5;
  }

  ul {
    @extend .list-unstyled;
    display: flex;
    flex-direction: column;

    li {
      display: flex;

      &:hover,
      .hover {
        background-color: __get($theme-colors, 'action-2');
      }

      a {
        color: __get($theme-colors, 'copytext');
        padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
        flex: 1;
        line-height: $nav-item-line-height;
      }
    }
  }
}

//search results page
#tx-solr-search {
  .tx-solr-search-top {
    grid-template-columns: auto;
    grid-template-rows: auto;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
    }

    .tx-solr-search-facets {
      grid-column: 1;
      grid-row: 2;
      z-index: 1;

      @include media-breakpoint-up(xl) {
        grid-column: 2;
        grid-row: 1;
      }
    }

    .tx-solr-search-facets__active {
      grid-column: 1;
      grid-row: 3;

      @include media-breakpoint-up(xl) {
        grid-row: 2;
      }
    }
  }

  #site-search-input-results {
    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(20px);
    }
  }
}
