.frame-type-theme_collapsible_element {
  &:last-of-type .collapsible {
    border-bottom: $border-width $border-style $border-color;
  }

  .frame + & {
    margin-top: 0;
    margin-bottom: 0;
  }

  .collapsible {
    border-top: $border-width $border-style $border-color;

    button {
      --bs-btn-font-size: #{px-to-rem(22px)};

      @include media-breakpoint-up(xl) {
        --bs-btn-font-size: #{px-to-rem(28px)};
      }

      --bs-btn-color: #{__get($theme-colors, 'copytext')};
      --bs-btn-border-width: 0;

      &[aria-expanded='true'] {
        --bs-btn-color: #{__get($theme-colors, 'primary')};

        svg {
          transform: rotate(-180deg);
        }
      }

      &:active:focus-visible {
        box-shadow: none;
      }

      svg {
        flex: 0 $grid-gutter-width * 0.75;
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
        min-width: $grid-gutter-width * 0.75;
        color: __get($theme-colors, 'primary');
      }
    }
  }
}
