@use 'sass:math';

.frame-type-productfinder_inquiry {
  .address-list {
    .btn {
      @include media-breakpoint-down(sm) {
        --bs-btn-padding-x: #{px-to-rem(16px)};
      }
    }
  }

  .product-inquiry {
    &__product,
    &__plumber {
      flex: 1 0 calc(#{percentage(math.div(6, 12))} - #{$grid-gutter-width * 0.5});

      h2 {
        --heading-font-size: #{px-to-rem(24px)};
      }
    }

    &__plumber {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;

        @include media-breakpoint-up(xl) {
          left: $grid-gutter-width * -0.5;
          width: 1px;
          height: 100%;
        }

        background: $body-color;
      }
    }
  }
}
