.facts-figures {
  color: __get($theme-colors, 'primary');

  &__value {
    font-family: $headings-font-family;
    font-size: px-to-rem(72px);

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(112px);
    }

    line-height: 1;

    span + span {
      display: none;
    }

    @media (prefers-reduced-motion: reduce) {
      span {
        display: none;

        & + span {
          display: inline;
        }
      }
    }
  }

  &__label {
    font-family: $headings-font-family;
    font-size: px-to-rem(16px);

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(18px);
    }
  }
}
