.series {
  &__downloads {
    &-lightbox {
      display: none;

      #glightbox-body & {
        display: block;
      }
    }
  }
}
