@use 'sass:math';

.hero {
  &__wrapper {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: minmax(100%, 1fr);
  }

  &__image,
  &__video,
  &__banner {
    grid-column: 1;
  }

  &__image,
  &__video {
    grid-row: 1;
  }

  &__banner {
    z-index: 1;
    grid-row: 2;

    @include media-breakpoint-up(md) {
      grid-row: 1;
    }

    &-text {
      flex: 0 0 percentage(math.div(12, 12));

      h1 {
        font-size: px-to-rem(32px);

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(48px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(72px);
        }
      }

      @include media-breakpoint-up(md) {
        flex: 0 0 percentage(math.div(9, 12));
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 percentage(math.div(7, 12));
      }

      @include media-breakpoint-up(ultrawide) {
        flex: 0 0 percentage(math.div(8, 12));
      }
    }

    @include media-breakpoint-up(md) {
      transform: translateY(var(--hero-text-position-y));
      will-change: transform;
    }
  }

  &__video {
    &-controls {
      z-index: 1;
      grid-column: 1;
      grid-row: 1;

      &-play {
        border: none;
        background: none;
        --control-color: #{__get($theme-colors, 'copytext')};
        --control-opacity: 0.3;

        &:hover {
          --control-color: #{__get($theme-colors, 'primary')};
          --control-opacity: 1;
        }
      }
    }
  }
}
