.navigation {
  li:not(.navigation__meta-item) a {
    text-decoration: none;
    color: __get($theme-colors, 'copytext');

    &.active,
    &:hover {
      color: __get($theme-colors, 'primary');
    }

    &:focus,
    &.focus {
      background: __get($theme-colors, 'primary');
      color: __get($theme-colors, 'white');
    }
  }

  &__meta-item > a {
    text-transform: initial;
    font-family: $font-family-base;
    font-size: $dropdown-font-size;

    @include media-breakpoint-down(xl) {
      line-height: $nav-item-line-height;
    }

    &.btn-primary,
    &.btn-outline-primary {
      font-family: $headings-font-family;
    }

    @include media-breakpoint-up(xl) {
      font-size: initial;
    }
  }

  &__button {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    height: 100%;
    aspect-ratio: 1 / 1;
    color: inherit;

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &-level-1 {
    .nav-open & {
      overflow-x: auto;
    }

    > li:not(.navigation__meta-item) {
      display: flex;
      position: relative;
      flex-direction: column;

      > a {
        font-family: $headings-font-family;
        text-transform: uppercase;
        padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;

        @include media-breakpoint-down(xl) {
          line-height: $nav-item-line-height;
        }

        @include media-breakpoint-up(xl) {
          padding: $grid-gutter-width * 0.5;
        }

        flex: 1 0 auto;
        justify-content: space-between;

        @include media-breakpoint-down(xl) {
          svg {
            transform: rotate(-90deg);
          }
          &.show svg {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  &-level-2 {
    position: relative !important;

    &.show {
      .nav-open & {
        transform: translate(0) !important;
        display: flex;
      }
    }

    > li {
      display: flex;

      > a {
        padding: $grid-gutter-width * 0.25 0 $grid-gutter-width * 0.25 $grid-gutter-width * 0.75;
        flex: 1 0 auto;
        @include media-breakpoint-down(xl) {
          line-height: $nav-item-line-height;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      position: absolute !important;
      padding: $grid-gutter-width * 0.5 0;

      > li {
        display: flex;

        > a {
          padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
          flex: 1 1 auto;
          white-space: nowrap;
          word-break: keep-all;
        }
      }
    }
  }

  &-separator {
    text-transform: uppercase;
    color: __get($theme-colors, 'primary');
    font-size: px-to-rem(14px);
    letter-spacing: 0.05rem;
    padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.5 $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;

    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
      margin-top: $grid-gutter-width * 0.5;
    }
  }

  &__wrapper {
    overflow-y: scroll;
    max-height: calc(100vh - 104px);
    height: 100%;

    @include media-breakpoint-up(xl) {
      overflow-y: unset;
    }
  }

  &__main {
    position: fixed;
    inset: 0;
    top: var(--main-navigation-height, 68px);

    @include media-breakpoint-up(xl) {
      position: static;
      inset: auto;

      .navigation__meta-item {
        display: none;
      }
    }
  }

  &__main-footer-links a {
    --bs-link-color: #000000;
    text-decoration: none;
  }

  &__main-footer {
    select {
      width: auto;
      padding: $grid-gutter-width * 0.5 $grid-gutter-width * 1.5 $grid-gutter-width * 0.5 $grid-gutter-width * 0.75;
      border: 1px solid $input-border-color;
      line-height: 1.5;
      &:focus {
        border: 1px solid $input-border-color;
      }
    }
  }

  &__footer {
    font-size: px-to-rem(16px);

    a {
      --bs-link-color: #{__get($theme-colors, 'copytext')};
      text-decoration: none;
    }

    li {
      &:last-of-type {
        flex: 1 0 100%;

        @include media-breakpoint-up(xl) {
          flex: 0 auto;
        }
      }
    }
  }

  &__breadcrumb {
    @include media-breakpoint-down(lg) {
      li {
        &:not(.last-visible) {
          display: none;
        }

        &.last-visible {
          svg {
            order: -1;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__jumpto {
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    a {
      color: __get($theme-colors, 'copytext');
      white-space: nowrap;

      &.active,
      &:hover {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          background: __get($theme-colors, 'copytext');
          bottom: -27px;
          left: 0;
        }
      }

      &.active {
        font-family: $headings-font-family;
      }
    }
  }

  &__meta-item {
    display: flex;

    .btn.show {
      border: 2px solid __get($theme-colors, 'primary');
    }

    .btn {
      @include media-breakpoint-down(xl) {
        font-size: px-to-rem(18px);
      }
    }

    &.dropdown a {
      @include media-breakpoint-down(xl) {
        .icon {
          transform: rotate(-90deg);
        }

        &.show .icon {
          transform: rotate(0deg);
        }
      }
    }
  }

  &-super {
    overflow-x: scroll;
  }
}

.nav {
  &-tabs {
    border-bottom: 0;
  }

  &-item {
    margin-left: $nav-link-padding-x;
    margin-right: $nav-link-padding-x;
    &:first-child {
      margin-left: 0;
    }
  }

  &-link {
    color: $nav-tabs-link-color;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $grid-gutter-width * 0.5;
    border: none !important;
    border-bottom: $nav-tabs-border-width solid transparent !important;

    &.active,
    &:hover {
      font-weight: $nav-link-active;
      border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color !important;
    }
  }
}

body #header:has(#nav-main.show) {
  --sticky-top: 0;
}
