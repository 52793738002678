.frame-type-theme_timeline {
  .timeline-entry {
    &:first-child {
      .timeline-item:before {
        grid-row: 2 / 3;
      }
    }

    @include media-breakpoint-up(xl) {
      &:nth-child(2n) {
        .timeline-item {
          &__content {
            grid-column: 3;
          }

          &__year {
            grid-column: 1;

            .h2 {
              text-align: end !important;
            }
          }
        }
      }
    }

    .timeline-item {
      grid-template-rows: auto 1fr;
      grid-template-columns: $timeline-bullet-width 1fr;

      @include media-breakpoint-up(md) {
        grid-template-rows: 84px 1fr;
      }

      @include media-breakpoint-up(xl) {
        grid-template-rows: 65px 1fr;
        grid-template-columns: 1fr $timeline-bullet-width 1fr;
      }

      @include media-breakpoint-up(ultrawide) {
        grid-template-rows: 128px 1fr;
      }

      &__year {
        grid-column: 1 / span 2;
        grid-row: 1;

        @include media-breakpoint-up(md) {
          grid-column: 2;
        }

        .h2 {
          color: __get($theme-colors, 'primary');
        }

        @include media-breakpoint-up(xl) {
          grid-column: 3;
          grid-row: 1/3;
        }
      }

      &__content {
        grid-column: 1 / span 2;
        grid-row: 2;

        @include media-breakpoint-up(md) {
          grid-column: 2;
        }

        h2 {
          color: __get($theme-colors, 'dark');
        }

        @include media-breakpoint-up(xl) {
          grid-row: 1/3;
          grid-column: 1;
        }
      }

      // line
      &:before {
        content: '';
        display: none;
        width: 1px;
        background-color: $sand-200;
        grid-row: 1/ 3;
        grid-column: 2 / span 2;

        .timeline-entry:first-child & {
          grid-row: span 1/ 3;
        }

        @include media-breakpoint-up(md) {
          display: block;
          grid-column: 1;
          justify-self: center;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 2;
          margin-left: 0;
        }
      }

      // dot
      &:after {
        content: '';
        display: none;
        grid-row: 2;
        grid-column: 2 / span 2;
        transform: translateY(-50%);
        height: $timeline-bullet-width;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: __get($theme-colors, 'primary');

        @include media-breakpoint-up(md) {
          display: block;
          grid-column: 1;
          justify-self: center;
        }

        @include media-breakpoint-up(xl) {
          grid-column: 2;
          margin-left: 0;
        }
      }
    }
  }
}
