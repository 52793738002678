@use 'sass:math';

.slider {
  &-wrapper {
    --slide-width: 263px;
    --flex-item: 0 0 var(--slide-width);
    --bs-gutter-x: 2rem;
    --full-width-grid-margin: calc(var(--bs-gutter-x) * 0.5);
    --track-column: 2 / 3;
    --flex-wrap: nowrap;
    --gap-column: #{$grid-gutter-width * 0.75};
    --gap: #{$grid-gutter-width * 0.75} var(--gap-column);
    --template-rows: auto;

    @each $breakpoint, $values in $grid-breakpoints {
      @include media-breakpoint-up($breakpoint) {
        @for $i from 1 through 5 {
          &.slides-per-view-#{$breakpoint}-#{$i} {
            --slides-count: #{$i};
            --slides-gaps: #{$i - 1};
          }
        }
      }
    }

    grid-template-columns: auto;
    grid-template-rows: var(--template-rows);
    z-index: 1;
    align-items: center;

    @include media-breakpoint-up(md) {
      --slide-width: 310px;
      --gap-column: #{$grid-gutter-width};
    }

    @include media-breakpoint-up(xl) {
      --slide-width: calc((100% - (var(--slides-gaps) * (var(--gap-column)))) / var(--slides-count));
      --flex-item: 0 1 var(--slide-width);
      --track-column: 1;
      --flex-wrap: wrap;
      --gap-column: #{$grid-gutter-width * 2};

      &.slides-desktop-slide {
        --flex-wrap: nowrap;
        --flex-item: 0 0 var(--slide-width);
      }
    }
  }

  &-track {
    grid-column: var(--track-column);
    grid-row: 1;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: none;
    flex-wrap: var(--flex-wrap);
    gap: var(--gap);

    @include media-breakpoint-up(xl) {
      scrollbar-width: none;
    }
  }

  &-item {
    --scroll-snap-align: center;

    flex: var(--flex-item);
    scroll-snap-align: var(--scroll-snap-align);
    height: fit-content;
    padding: $grid-gutter-width * 0.125;

    @include media-breakpoint-up(xl) {
      --scroll-snap-align: start;
    }

    &[tabindex]:focus-visible {
      outline-offset: -2px;
    }
  }

  &-controls {
    grid-column: 1;
  }

  &-control {
    --bs-btn-border-width: 0;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-color: #{__get($theme-colors, 'primary')};
    --bs-btn-active-color: #{__get($theme-colors, 'primary')};
    --bs-btn-disabled-color: #{$gray-300};

    z-index: 2;
    width: fit-content;
    height: fit-content;
    opacity: 1;
    line-height: 1;
    padding: $grid-gutter-width * 0.75 $grid-gutter-width * 0.375;
    margin: 0;
    border-color: transparent;
    background-color: transparent;
    transition: none;

    &.hidden {
      display: none;
    }

    &__separator {
      position: relative;
      display: flex;
      align-items: center;
      width: 2px;
      margin: 0 $grid-gutter-width * 0.25;

      &:after {
        content: '';
        width: 2px;
        background-color: $gray-300;
        height: 36px;
        position: absolute;
        border-radius: 1px;
      }

      &.hidden {
        display: none;
      }
    }
  }

  &-dots {
    grid-column: 2 / 3;
    grid-row: 2;
    display: none;

    &.hidden {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
    }

    &__item {
      button {
        height: 8px;
        width: 24px;
        border: none;
        background-color: __get($theme-colors, 'medium');

        &.active {
          background-color: __get($theme-colors, 'dark');
          width: 48px;
          pointer-events: none;
        }
      }
    }
  }
}
