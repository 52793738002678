body {
  --bs-body-font-size: #{px-to-rem(18px)};
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  scroll-behavior: smooth;

  &.nav-open,
  &.tx-supi__overlay {
    overflow: hidden;
  }
}
