.bodytext {
  > ul,
  > ol {
    margin-bottom: $grid-gutter-width * 1.5;
    .text-center & {
      text-align: start;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width * 0.5;

    li {
      position: relative;
      padding-left: $grid-gutter-width * 1.25;

      &:before {
        position: absolute;
        top: 3px;
        left: 0;
        content: '';
        background: url($icon-ul) no-repeat center;
        background-size: contain;
        width: $grid-gutter-width * 0.75;
        height: $grid-gutter-width * 0.75;
      }
    }

    ul {
      flex: 1 0 100%;
      margin-top: $grid-gutter-width * 0.5;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width * 0.5;

    li {
      padding-left: $grid-gutter-width * 0.25;
    }

    ol {
      margin-top: $grid-gutter-width * 0.5;
    }
  }
}
