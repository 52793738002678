.frame-type-theme_icon_teaser {
  h2 {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(xl) {
      text-align: center;
    }
  }

  // layout option: align left
  &.frame-layout-1589368567 {
    h2 {
      text-align: left;
    }
  }

  .card {
    --bs-card-border-width: 0;
    --bs-card-inner-border-radius: 0;
    --bs-card-spacer-y: 0;
    --bs-card-spacer-x: 0;

    &-title {
      font-size: px-to-rem(24px);
    }

    &-text {
      ul li {
        margin: $grid-gutter-width * 0.25 0;
      }
    }
  }
}
