@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../Webfonts/35D065_5_0.woff2') format('woff2'), url('../Webfonts/35D065_5_0.woff') format('woff'),
    url('../Webfonts/35D065_5_0.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../Webfonts/35D065_8_0.woff2') format('woff2'), url('../Webfonts/35D065_8_0.woff') format('woff'),
    url('../Webfonts/35D065_8_0.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-RegularIt';
  src: url('../Webfonts/35D065_B_0.woff2') format('woff2'), url('../Webfonts/35D065_B_0.woff') format('woff'),
    url('../Webfonts/35D065_B_0.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../Webfonts/firasans/FiraSans-Bold.eot');
  src: url('../Webfonts/firasans/FiraSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../Webfonts/firasans/FiraSans-Bold.woff2') format('woff2'),
    url('../Webfonts/firasans/FiraSans-Bold.woff') format('woff'),
    url('../Webfonts/firasans/FiraSans-Bold.ttf') format('truetype'),
    url('../Webfonts/firasans/FiraSans-Bold.svg#FiraSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
