.accordion {
  --bs-accordion-top-space: 0;

  --bs-accordion-btn-padding-y: #{$grid-gutter-width * 0.75};
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-font-size: #{px-to-rem(22px)};

  --bs-accordion-btn-icon: #{$accordion-button-icon};
  --bs-accordion-btn-active-icon: #{$accordion-button-icon};

  --bs-accordion-body-padding-y: #{$grid-gutter-width * 0.25} 0;
  --bs-accordion-body-padding-x: #{$grid-gutter-width * 1.25} 0;

  @include media-breakpoint-up(xl) {
    --bs-accordion-top-space: #{$grid-gutter-width};

    --bs-accordion-btn-padding-y: #{$grid-gutter-width};
    --bs-accordion-btn-padding-x: #{$grid-gutter-width * 0.5};
    --bs-accordion-btn-font-size: #{px-to-rem(28px)};

    --bs-accordion-body-padding-y: #{$grid-gutter-width * 0.25} #{$grid-gutter-width * 0.5};
    --bs-accordion-body-padding-x: #{$grid-gutter-width * 1.25} #{$grid-gutter-width * 0.5};
  }

  @include media-breakpoint-up(ultrawide) {
    --bs-accordion-body-padding-x: #{$grid-gutter-width * 2} #{$grid-gutter-width * 0.5};
  }

  header + & {
    padding-top: var(--bs-accordion-top-space);
  }

  &-button {
    font-size: #{px-to-rem(22px)};
    line-height: #{px-to-rem(33px)};

    @include media-breakpoint-up(xl) {
      font-size: #{px-to-rem(28px)};
      line-height: #{px-to-rem(42px)};
    }

    &::after {
      display: none;
    }

    svg {
      transition: all 0.2s ease-in-out;
      transform: rotate(0);
      color: __get($theme-colors, 'primary');
      flex: 0 0 1.5rem;
    }

    &[aria-expanded='true'] svg {
      transform: rotate(-180deg);
    }
  }

  &-item {
    border-top: 1px solid __get($theme-colors, 'copytext');

    &:not(:first-of-type) {
      border-top: 1px solid __get($theme-colors, 'copytext');
    }

    &:last-of-type {
      border-bottom: 1px solid __get($theme-colors, 'copytext');
    }
  }
}
