@use 'sass:math';

.address {
  @include media-breakpoint-up(lg) {
    max-width: percentage(math.div(8, 12));
  }

  display: grid;
  grid-auto-columns: 1fr;

  @include media-breakpoint-up(lg) {
    grid-auto-columns: minmax(340px, 1fr) 1fr;
    column-gap: $grid-gutter-width;
  }

  grid-auto-rows: auto;

  &__heading {
    font-family: $headings-font-family;
    font-size: px-to-rem(20px);
    grid-column: 1 / span 2;
    margin-bottom: $grid-gutter-width * 0.25;
  }

  &__line {
    grid-column: 1 / span 2;
    margin-bottom: $grid-gutter-width * 0.125;

    @include media-breakpoint-up(md) {
      // rows 2-4
      @for $i from 2 through 4 {
        &:nth-child(#{$i}) {
          grid-column: 1;
          grid-row: #{$i};
        }
      }

      // row 5-7
      @for $i from 5 through 7 {
        &:nth-child(#{$i}) {
          grid-row: #{$i - 3};
          grid-column: 2;
        }
      }

      &-hours {
        grid-row: 8 !important;
        grid-column: 1 / span 2 !important;
        margin-bottom: 0;
      }
    }

    &-key {
      font-family: $headings-font-family;
      margin-bottom: $grid-gutter-width * 0.25;
    }

    &-value {
      line-height: 1.8;
    }
  }
}
