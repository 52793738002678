@use 'sass:math';

// load all variables
@import 'breakpoints';
@import 'container';
@import 'aspect-ratios';

// Colors
$sand-100: #f9f6f2;
$sand-150: #ebe2d3;
$sand-200: #ded0b7;
$sand-400: #cdbca0;

$gray-100: #f2f2f2;
$gray-200: #d9d9d9;
$gray-300: #bfbfbf;
$gray-400: #a6a6a6;
$gray-500: #8c8c8c;
$gray-600: #737373;
$gray-700: #595959;
$gray-800: #4c4d4f;
$gray-900: #262626;

$green-700: #529231;

$citrine-500: #e1d716;

$atlantic-blue: #0063b0;
$atlantic-green: #00a675;
$atlantic-orange: #f05722;
$atlantic-yellow: #fcb514;
$atlantic-gray-dark: #54565a;
$atlantic-gray-light: #d1ccc4;

$primary-25: rgba(255, 226, 232, 0.25);
$primary-50: #ffe2e8;
$primary-100: #ffb1bd;
$primary-200: #ff7f93;
$primary-300: #ff4d67;
$primary-400: #fe1d3d;
$primary-500: #e50523;
$primary-600: #c9001e;
$primary-700: #810012;
$primary-800: #4f0009;
$primary-900: #200002;

$white: #ffffff;
$black: #000000;
$blue: #f1f7fb;
$blue-200: #e7f0f2;
$red: $primary-600;
$green: $green-700;
$yellow: $citrine-500;

$primary: $primary-600;
$secondary: $gray-600;
$success: $green-700;
$info: $atlantic-green;
$warning: $citrine-500;
$danger: $primary-700;
$light: $gray-100;
$dark: $gray-900;

$body-color: $black;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'copytext': $body-color,
  'white': $white,
  'action-1': $sand-100,
  'action-2': $blue,
  'action-3': $blue-200,
);

// Bootstrap options
$enable-rfs: false;
$enable-negative-margins: true;

// Grid settings
$grid-breakpoints: map-loop($grid-breakpoints, 'px-to-rem', '$value');
$container-max-widths: map-loop($container-max-widths, 'px-to-rem', '$value');

// fonts and sizes
$font-family-sans-serif: 'ProximaNova-Regular', sans-serif;
$font-family-base: $font-family-sans-serif;

$headings-font-family: 'ProximaNova-Bold', sans-serif;
$headings-font-weight: normal;

$font-size-root: 16px;
$font-size-base: 1rem;
$small-font-size: px-to-rem(14px);

$heading-sizes: (
  1: (
    xs: (
      size: 40px,
      margin: 40px,
      line-height: 1.2,
    ),
    xl: (
      size: 76px,
      margin: 64px,
      line-height: 1.2,
    ),
    ultrawide: (
      size: 76px,
      margin: 96px,
      line-height: 1.2,
    ),
  ),
  2: (
    xs: (
      size: 32px,
      margin: 32px,
      line-height: 1.2,
    ),
    xl: (
      size: 54px,
      margin: 32px,
      line-height: 1.2,
    ),
  ),
  3: (
    xs: (
      size: 24px,
      margin: 16px,
      line-height: 1.2,
    ),
    xl: (
      size: 32px,
      margin: 24px,
      line-height: 1.2,
    ),
  ),
  4: (
    xs: (
      size: 20px,
      margin: 16px,
      line-height: 1.2,
    ),
    xl: (
      size: 22px,
      margin: 24px,
      line-height: 1.2,
    ),
  ),
);

$font-sizes: (
  1: px-to-rem(__get($heading-sizes, 1 'xs' 'size')),
  2: px-to-rem(__get($heading-sizes, 2 'xs' 'size')),
  3: px-to-rem(__get($heading-sizes, 3 'xs' 'size')),
);

// Empty map to generate no display-classes
$display-font-sizes: ();

$grid-gutter-width: 2rem;

// spacers for margin and padding spaces
// use classnames like px-fg or px-lg-fg
// for full gutter padding on x-axis or
// full gutter padding lg or bigger on x-axis
$spacers: (
  0: 0,
  vsg: $grid-gutter-width * 0.125,
  sg: $grid-gutter-width * 0.25,
  hg: $grid-gutter-width * 0.5,
  qp: $grid-gutter-width * 0.75,
  fg: $grid-gutter-width,
  1sg: $grid-gutter-width * 1.25,
  1h: $grid-gutter-width * 1.5,
  1tq: $grid-gutter-width * 1.75,
  2h: $grid-gutter-width * 2.5,
  2tq: $grid-gutter-width * 2.75,
  dg: $grid-gutter-width * 2,
  tg: $grid-gutter-width * 3,
  qg: $grid-gutter-width * 4,
  4h: $grid-gutter-width * 4.5,
  5h: $grid-gutter-width * 5.5,
);

// borders
$border-radius: 0;
$border-color: $black;

// buttons
$btn-font-family: $headings-font-family;
$btn-border-width: 2px;

$btn-border-radius: $border-radius;

$btn-padding-y-sm: 10px;
$btn-padding-x-sm: 22px;
$btn-border-radius-sm: ß;
$btn-font-size-sm: px-to-rem(16px);

$btn-border-radius-lg: $btn-border-radius;

// shadow
$box-shadow: 0px 4px 16px rgba(50, 50, 50, 0.1);
$box-shadow-hpa: 0 3px 5px rgba(50, 50, 50, 0.2);

// dropdown
$dropdown-border-color: transparent;
$dropdown-font-size: px-to-rem(18px);

// links and hover
$link-hover-color: $primary;
$link-decoration: none;

// animations
$animation-method: cubic-bezier(0.6, 0.2, 0.19, 1.45);
$animation-method-image-hover: 0.33s ease-out;

// forms
$form-label-font-size: px-to-rem(14px);
$form-label-color: $gray-800;
$form-label-margin-bottom: $grid-gutter-width * 0.125;

$input-border-color: __get($theme-colors, 'copytext');
$input-border-width: 0;
$input-font-size: px-to-rem(18px);
$input-padding-x: $grid-gutter-width * 0.5;
$input-padding-y: $grid-gutter-width * 0.25;

$form-select-line-height: px-to-rem(50px);

$form-check-input-width: 1.2em;
$form-check-input-height: $form-check-input-width;
$form-check-input-border: none;
$form-check-input-checked-bg-color: transparent;

$form-check-input-unchecked-bg-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="3.00049" width="17.25" height="18" fill="white"/><path d="M19.5 1.50049C21.1547 1.50049 22.5 2.84346 22.5 4.50049V19.5005C22.5 21.1552 21.1547 22.5005 19.5 22.5005H4.5C2.84297 22.5005 1.5 21.1552 1.5 19.5005V4.50049C1.5 2.84346 2.84297 1.50049 4.5 1.50049H19.5ZM19.5 3.75049H4.5C4.08562 3.75049 3.75 4.08611 3.75 4.50049V19.5005C3.75 19.913 4.08562 20.2505 4.5 20.2505H19.5C19.9125 20.2505 20.25 19.913 20.25 19.5005V4.50049C20.25 4.08611 19.9125 3.75049 19.5 3.75049Z" fill="black"/></svg>');
$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="3" width="17.25" height="18" fill="white"/><path d="M11.4281 15.9281C10.9172 16.4391 10.0828 16.4391 9.57187 15.9281L6.57187 12.9281C6.05953 12.4172 6.05953 11.5828 6.57187 11.0719C7.08281 10.5609 7.91719 10.5609 8.42813 11.0719L10.5 13.1437L15.5719 8.07187C16.0828 7.56094 16.9172 7.56094 17.4281 8.07187C17.9391 8.58281 17.9391 9.41719 17.4281 9.92813L11.4281 15.9281ZM1.5 4.5C1.5 2.84297 2.84297 1.5 4.5 1.5H19.5C21.1547 1.5 22.5 2.84297 22.5 4.5V19.5C22.5 21.1547 21.1547 22.5 19.5 22.5H4.5C2.84297 22.5 1.5 21.1547 1.5 19.5V4.5ZM3.75 4.5V19.5C3.75 19.9125 4.08562 20.25 4.5 20.25H19.5C19.9125 20.25 20.25 19.9125 20.25 19.5V4.5C20.25 4.08562 19.9125 3.75 19.5 3.75H4.5C4.08562 3.75 3.75 4.08562 3.75 4.5Z" fill="black"/></svg>');

$form-check-radio-unchecked-bg-image: url('data:image/svg+xml,<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 10.5005C21.5 16.3001 16.7996 21.0005 11 21.0005C5.20039 21.0005 0.5 16.3001 0.5 10.5005C0.5 4.70088 5.20039 0.000488281 11 0.000488281C16.7996 0.000488281 21.5 4.70088 21.5 10.5005ZM11 1.96924C6.2873 1.96924 2.46875 5.78779 2.46875 10.5005C2.46875 15.2132 6.2873 19.0317 11 19.0317C15.7127 19.0317 19.5312 15.2132 19.5312 10.5005C19.5312 5.78779 15.7127 1.96924 11 1.96924Z" fill="black"/></svg>');
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.0625 10.5005C7.0625 8.28975 8.78926 6.56299 11 6.56299C13.1738 6.56299 14.9375 8.28975 14.9375 10.5005C14.9375 12.6743 13.1738 14.438 11 14.438C8.78926 14.438 7.0625 12.6743 7.0625 10.5005ZM21.5 10.5005C21.5 16.3001 16.7996 21.0005 11 21.0005C5.20039 21.0005 0.5 16.3001 0.5 10.5005C0.5 4.70088 5.20039 0.000488281 11 0.000488281C16.7996 0.000488281 21.5 4.70088 21.5 10.5005ZM11 1.96924C6.2873 1.96924 2.46875 5.78779 2.46875 10.5005C2.46875 15.2132 6.2873 19.0317 11 19.0317C15.7127 19.0317 19.5312 15.2132 19.5312 10.5005C19.5312 5.78779 15.7127 1.96924 11 1.96924Z" fill="black"/></svg>');

$form-feedback-icon-invalid: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23810012'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23810012' stroke='none'/%3e%3c/svg%3e";

$form-input-select-bg-image: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234c4d4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e";

$form-file-button-hover-bg: $primary-500;

$form-feedback-invalid-color: __get($theme-colors, 'primary');

$search-form-width: 250px;

$carousel-indicator-width: 12px;

$input-btn-focus-box-shadow: none;

$form-feedback-valid-color: __get($theme-colors, 'success');
$form-feedback-invalid-color: __get($theme-colors, 'danger');

// mark
$mark-padding: 0;
$mark-bg: transparent;

// timeline
$timeline-bullet-width: 17px;

// icons
$icon-sizes: 15, 21, 24, 28, 32, 40, 56, 64, 88, 96;

$icon-logobar-size: px-to-rem(40px);

// tables
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 0.5rem;
$table-hover-bg: __get($theme-colors, 'action-1');

// images
$thumbnail-border-radius: 100%;
$thumbnail-padding: 0;
$thumbnail-border-width: 0;

// accordion
$accordion-border-width: 0;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: __get($theme-colors, 'primary');
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: none;
$accordion-icon-color: __get($theme-colors, 'primary');
$accordion-icon-active-color: $accordion-icon-color;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg fill='none' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='ico-caret'%3E%3Cpath d='M5.99954 7.39027C5.74767 7.39027 5.49569 7.29414 5.30374 7.10189L0.382126 2.18027C-0.00237536 1.79577 -0.00237536 1.17288 0.382126 0.788376C0.766628 0.403875 1.38952 0.403875 1.77402 0.788376L5.99954 5.01558L10.226 0.789145C10.6105 0.404643 11.2334 0.404643 11.6179 0.789145C12.0024 1.17365 12.0024 1.79654 11.6179 2.18104L6.69625 7.10266C6.504 7.29491 6.25177 7.39027 5.99954 7.39027Z' fill='currentColor'/%3E%3C/g%3E%3C/svg%3E");

// svg inlines
$icon-location: 'data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3Csvg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="ico-pin"%3E%3Cpath d="M12.2211 13.3694C10.3442 13.3694 8.82251 11.8476 8.82251 9.97082C8.82251 8.094 10.3442 6.57227 12.2211 6.57227C14.0979 6.57227 15.6196 8.094 15.6196 9.97082C15.6196 11.8476 14.0979 13.3694 12.2211 13.3694ZM12.2211 8.2969C11.2979 8.2969 10.5471 9.04763 10.5471 9.97082C10.5471 10.894 11.2979 11.6447 12.2211 11.6447C13.1442 11.6447 13.895 10.894 13.895 9.97082C13.895 9.04763 13.1442 8.2969 12.2211 8.2969Z" fill="%23FFFFFF"/%3E%3Cpath d="M12.2312 22.5C11.9167 22.5 11.592 22.3884 11.3384 22.1652C9.5529 20.6029 3.75 15.1348 3.75 10.1333C3.75 5.13188 7.55435 1.5 12.221 1.5C16.8877 1.5 20.692 5.37536 20.692 10.1333V10.2145C20.692 15.5101 15.3659 20.3594 13.0833 22.2058C12.8297 22.4087 12.5355 22.5 12.2312 22.5ZM12.221 3.22464C8.49783 3.22464 5.47464 6.32899 5.47464 10.1333C5.47464 14.2725 10.679 19.2638 12.2413 20.6638C14.0978 19.1319 18.9674 14.6783 18.9674 10.2145V10.1333C18.9674 6.32899 15.9442 3.22464 12.221 3.22464Z" fill="%23FFFFFF"/%3E%3C/svg%3E';
$icon-ul: 'data:image/svg+xml,%3Csvg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M26.6694 0.871265C27.2671 1.42205 27.2671 2.31267 26.6694 2.81072L11.2007 18.2795C10.7026 18.8771 9.81201 18.8771 9.26123 18.2795L1.29418 10.3107C0.745039 9.81267 0.745039 8.92205 1.29418 8.37126C1.84326 7.82634 2.73389 7.82634 3.28291 8.37126L10.2573 15.3498L24.73 0.871265C25.2808 0.323999 26.1714 0.323999 26.6694 0.871265Z" fill="%23C9001E"/%3E%3C/svg%3E%0A';

// nav
$nav-item-line-height: px-to-rem(32px);
$nav-tabs-link-color: __get($theme-colors, 'copytext');
$nav-tabs-link-active-color: __get($theme-colors, 'copytext');
$nav-tabs-link-hover-border-color: __get($theme-colors, 'copytext');
$nav-tabs-link-active-border-color: __get($theme-colors, 'copytext');
$nav-tabs-border-color: __get($theme-colors, 'copytext');
$nav-tabs-border-width: 3px;
$nav-link-hover-color: __get($theme-colors, 'copytext');
$nav-link-font-weight: normal;
$nav-link-active: bold;
