table.table {
  .series__types & {
    th,
    td {
      min-width: 150px;

      &:first-child {
        min-width: 200px;
      }

      &.downloads {
        width: 100px;
        min-width: auto;
      }
    }
  }

  &-striped {
    --bs-table-striped-bg: #{__get($theme-colors, 'action-2')};

    th {
      font-family: $headings-font-family;
    }

    thead th {
      background: __get($theme-colors, 'dark');
      color: __get($theme-colors, 'white');
    }

    tfoot th,
    tfoot td {
      background: __get($theme-colors, 'action-1');
    }
  }

  .text-center & {
    text-align: start !important;
  }
}
