.gallery-wrapper {
  --gallery-columns: 1;

  display: grid;
  grid-template-columns: repeat(var(--gallery-columns), 1fr);
  gap: $grid-gutter-width;

  @include media-breakpoint-up(md) {
    --gallery-columns: 3;
  }
}
