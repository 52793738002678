@use 'sass:math';

.textimage-wrapper {
  figure {
    margin-bottom: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .bodytext {
    p + p .btn {
      margin-top: $grid-gutter-width;
    }
  }

  &.imageorient {
    // image right, content left
    &-25 {
      .bodytext {
        order: 1;

        @include media-breakpoint-up(md) {
          order: 0;
        }

        @include media-breakpoint-up(ultrawide) {
          padding-right: $grid-gutter-width * 2.5;
        }
      }
    }

    // image left, content right
    &-26 {
      .bodytext {
        @include media-breakpoint-up(ultrawide) {
          padding-left: $grid-gutter-width * 2.5;
        }
      }
    }
  }
}
