.logobar-wrapper {
  .logobar-icon svg {
    height: $icon-logobar-size;
    width: auto;

    @include media-breakpoint-up(xl) {
      height: $icon-logobar-size * 1.2;
    }

    @include media-breakpoint-up(ultrawide) {
      height: $icon-logobar-size * 1.5;
    }
  }
}
