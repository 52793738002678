@use 'sass:math';

.frame-type-productfinder_advisor {
  .steps {
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
  }

  .step {
    flex: 1 0 100%;
    //scroll-snap-align: start;
    display: flex;
    margin: 0;
    flex-direction: column;
    gap: $grid-gutter-width * 0.5;
    visibility: hidden;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      gap: 0;
    }

    &.active {
      visibility: visible;
    }

    &:not(.active) {
      overflow: hidden;

      &:not(.d-none) {
        height: 0;
      }
    }

    &__text {
      flex: 1 1 calc(#{percentage(math.div(5, 12))} - #{$grid-gutter-width * 0.25});
      word-break: break-word;
    }

    &__aside {
      flex: 1 0 calc(#{percentage(math.div(7, 12))} - #{$grid-gutter-width * 0.25});
      flex-direction: column;
      gap: $grid-gutter-width * 0.75;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-left: $grid-gutter-width * 1.75;
      }

      // 1 item
      &:has(:not(.step__image:nth-child(2))) {
        --heatpump-image-width: 100%;
        --heatpump-image-gutter: 0px;
      }

      // 2 item
      &[data-count='2'] {
        &:has(:not(.step__image:nth-child(3))) {
          --heatpump-image-width: 50%;
          --heatpump-image-gutter: #{$grid-gutter-width * 0.25};
        }
      }

      // 3 items (2 visible)
      &[data-count='3'] {
        &:has(.step__image:nth-child(3).d-none) {
          --heatpump-image-width: 50%;
          --heatpump-image-gutter: #{$grid-gutter-width * 0.25};
        }
      }

      // 3 items
      &[data-count='3'] {
        &:has(:not(.step__image:nth-child(4))) {
          --heatpump-image-width: #{percentage(math.div(4, 12))};
          --heatpump-image-gutter: #{$grid-gutter-width * 0.375};
        }
      }

      // 4 items
      &[data-count='4'] {
        &:has(:not(.step__image:nth-child(5))) {
          --heatpump-image-width: #{percentage(math.div(3, 12))};
          --heatpump-image-gutter: #{$grid-gutter-width * 0.375};

          @include media-breakpoint-up(xl) {
            --heatpump-image-width: #{percentage(math.div(6, 12))};
            --heatpump-image-gutter: #{$grid-gutter-width * 0.25};
          }
        }
      }

      // more than 4 items
      &[data-count='more'] {
        &:has(.step__image:nth-child(5)) {
          --heatpump-image-width: #{percentage(math.div(4, 12))};
          --heatpump-image-gutter: #{$grid-gutter-width * 0.375};
        }
      }
    }

    &__image {
      @include media-breakpoint-up(md) {
        gap: $grid-gutter-width;
        flex: 0 0 calc(var(--heatpump-image-width) - var(--heatpump-image-gutter));
        z-index: 1;
      }

      .single {
        @include media-breakpoint-up(md) {
          flex: 1 1 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:has(input:checked) .step__image-placeholder {
        background: __get($theme-colors, 'primary');
        color: __get($theme-colors, 'white');
      }

      &:hover {
        .form-check-label:after {
          opacity: 1;
        }

        .step__image-placeholder {
          background: __get($theme-colors, 'primary');
          color: __get($theme-colors, 'white');
        }
      }

      &-placeholder {
        width: 100%;
        height: 100%;
        background: __get($theme-colors, 'white');
        transition: all 0.2s ease-in-out;

        display: grid;
        place-content: center;
        font-family: $headings-font-family;
        color: __get($theme-colors, 'primary');

        span {
          font-size: px-to-rem(32px);
          &.single-text {
            font-size: px-to-rem(24px);
          }
          hyphens: auto;
          line-height: 1;
        }

        small {
          font-size: px-to-rem(16px);
        }
      }
    }

    &__input {
      font-size: inherit;
      text-align: left;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 1 0;

      .form-check-label {
        text-wrap: balance;
        word-break: break-word;

        @include media-breakpoint-up(md) {
          &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: __get($theme-colors, 'white');
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            z-index: -1;
            box-shadow: $box-shadow-hpa;
          }
        }
      }

      :checked + .form-check-label:after {
        opacity: 1;
      }

      [type='number'],
      [type='text'] {
        width: 100%;
        @include media-breakpoint-up(xl) {
          width: percentage(math.div(6, 12));
        }

        max-width: 100%;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        & + small {
          font-size: px-to-rem(14px);
          margin-top: $grid-gutter-width * 0.25;
        }
      }
    }
  }

  .summary {
    &__item {
      background: __get($theme-colors, 'white');
      display: flex;
      justify-content: space-between;
    }

    &__selected {
      display: flex;
      flex-direction: column;
      line-height: 1.6;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: center;
        gap: $grid-gutter-width * 0.5;
        line-height: 2.4;
      }
    }

    &__key {
      font-size: px-to-rem(16px);
      line-height: 1.5;
    }

    &__value,
    &__edit {
      font-family: $headings-font-family;
      line-height: 1.5;
    }

    &__edit {
      color: __get($theme-colors, 'primary');
    }
  }

  .progress-bar {
    --progress: 0%;

    background: __get($theme-colors, 'action-3');
    display: flex;

    &__progress {
      background: __get($theme-colors, 'primary');
      flex: 0 var(--progress);
      height: $grid-gutter-width * 0.25;
      transition: flex 0.2s ease-in-out;
    }
  }

  .results {
    &-top {
      &__data {
        @include media-breakpoint-up(md) {
          height: fit-content;
        }

        p {
          font-family: $headings-font-family;
          font-size: px-to-rem(24px);
        }
      }

      &__savings {
        font-size: px-to-rem(28px);
        font-family: $headings-font-family;

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(32px);
        }
      }
    }

    &-products {
      .table {
        th {
          font-family: $headings-font-family;
        }
      }

      &__image {
        &:not(.single) {
          width: 160px;
          @include media-breakpoint-up(md) {
            width: 180px;
          }
          @include media-breakpoint-up(xl) {
            width: 230px;
          }
          @include media-breakpoint-up(ultrawide) {
            width: 290px;
          }
        }

        img {
          object-fit: cover;
        }
      }

      &__name {
        font-size: px-to-rem(24px);
        font-family: $headings-font-family;

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(32px);
        }
      }
    }
  }

  .errorbox {
    width: fit-content;
  }
}
