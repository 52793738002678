.frame-type-theme_product_overview {
  .product-overview {
    &__wrapper {
      --product-overview-columns: 1;
      grid-template-columns: repeat(var(--product-overview-columns), minmax(0, 1fr));

      @include media-breakpoint-up(md) {
        --product-overview-columns: 2;
      }

      @include media-breakpoint-up(xl) {
        --product-overview-columns: 3;
      }

      @include media-breakpoint-up(ultrawide) {
        --product-overview-columns: 4;
      }
    }

    &__item {
      .icon {
        position: absolute;
        top: $grid-gutter-width;
        left: 0;

        @include media-breakpoint-up(xl) {
          top: $grid-gutter-width * 0.75;
        }
      }

      img {
        object-fit: contain;
      }

      .content-wrapper a.btn {
        width: fit-content;
      }
    }
  }
}
