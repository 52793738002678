.lightbox {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  picture,
  &__open {
    grid-column: 1;
    grid-row: 1;
  }

  &__open {
    z-index: 1;
    width: $grid-gutter-width * 1.5;
    height: $grid-gutter-width * 1.5;
    align-self: end;
    justify-self: end;
    border-radius: 100%;
  }
}
