.carousel {
  &-indicators {
    button {
      width: $carousel-indicator-width !important;
      height: $carousel-indicator-width !important;
      border-radius: 50%;
      background-color: $gray-500 !important;
      &.active {
        background-color: __get($theme-colors, 'primary') !important;
      }
    }
  }
}
