#header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: var(--sticky-top, -52px);
  transition: all 0.2s ease-in-out;

  &.sticky-visible,
  &.top {
    --sticky-top: 0px;
  }

  @include media-breakpoint-up(xl) {
    --sticky-top: -80px;

    &.sticky-visible {
      --sticky-top: -80px;
    }

    &.top {
      --sticky-top: 0px;
    }
  }

  body.tmpl-Landingpage & {
    --sticky-top: 0;
  }
}

.tmpl-Productseries {
  @include media-breakpoint-down(xl) {
    #header {
      --sticky-top: 0px;
    }
  }
}
