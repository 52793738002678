@each $order, $sizes in $heading-sizes {
  h#{$order},
  .h#{$order} {
    color: map-get($theme-colors, 'copytext');
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    hyphens: auto;
    text-wrap: balance;

    @each $breakpoint, $values in $sizes {
      @include media-breakpoint-up($breakpoint) {
        --heading-font-size: #{px-to-rem(__get($values, 'size'))};
        font-size: var(--heading-font-size);
        margin-bottom: px-to-rem(__get($values, 'margin'));
        line-height: __get($values, 'line-height');
      }
    }
  }
}

h1,
.h1 {
  color: __get($theme-colors, 'primary');
  hyphens: auto;
}

h2,
.h2 {
  .bg-action-1 & {
    color: __get($theme-colors, 'primary');
  }

  .frame-type-theme_download_box & {
    margin-bottom: $grid-gutter-width * 1.5;
  }

  .frame-type-form_formframework.bg-action-1 &,
  .frame-type-text.bg-action-1 & {
    color: __get($theme-colors, 'copytext');
  }

  .frame-type-form_formframework & {
    margin-bottom: $grid-gutter-width * 1.5;

    @include media-breakpoint-up(xl) {
      margin-bottom: px-to-rem(56px);
    }
  }

  @include media-breakpoint-up(ultrawide) {
    .frame-type-theme_product_teaser & {
      margin-bottom: px-to-rem(40px);
    }
  }

  .frame-type-theme_product_menu.bg-action-1 & {
    color: __get($theme-colors, 'copytext');
  }
}

#page-header:has(
    + #animation-wrapper
      .frame:not(
        .frame-type-form_formframework,
        .frame-type-theme_textimage,
        .frame-type-text,
        .frame-type-news_pi1 .frame-type-news_newsdetail
      ):first-child
      header
  ),
#page-header:has(+ #animation-wrapper .frame.frame-type-theme_teaser_text),
#page-header:has(+ #animation-wrapper .frame.frame-type-theme_product_teaser .teaser-text) {
  h1 {
    margin-bottom: $grid-gutter-width * 0.75;

    @include media-breakpoint-up(xl) {
      margin-bottom: $grid-gutter-width;
    }
  }
}
