@use 'sass:math';

:root {
  --maps-height: calc(100vh - 120px);
  --maps-width: 100vw;

  --heatpump-image-width: #{percentage(math.div(6, 12))};
  --heatpump-image-gutter: #{$grid-gutter-width * 0.25};

  --frame-margin-top: #{$grid-gutter-width * 2};
  --frame-margin-bottom: #{$grid-gutter-width * 2};

  @include media-breakpoint-up(md) {
    --frame-margin-top: #{$grid-gutter-width * 3};
    --frame-margin-bottom: #{$grid-gutter-width * 3};
  }

  @include media-breakpoint-up(xl) {
    --frame-margin-top: #{$grid-gutter-width * 4};
    --frame-margin-bottom: #{$grid-gutter-width * 4};
  }

  @include media-breakpoint-up(ultrawide) {
    --frame-margin-top: #{$grid-gutter-width * 4.5};
    --frame-margin-bottom: #{$grid-gutter-width * 4.5};
  }

  --container-max-width: 100%;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      --container-max-width: #{$container-max-width};
    }
  }
}
