@use 'sass:math';

.frame-type-theme_teaser_media {
  .card {
    --bs-card-border-width: 0;
    --bs-card-inner-border-radius: 0;
    --bs-card-spacer-y: 0;
    --bs-card-spacer-x: 0;
    --bs-card-title-spacer-y: #{$grid-gutter-width * 0.5};

    &-video {
      @include media-breakpoint-up(md) {
        flex: 1 0 percentage(math.div(4, 12));
      }

      @include media-breakpoint-up(xl) {
        flex: 1;
      }
    }

    &-title {
      font-size: px-to-rem(24px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(32px);
      }
    }
  }
}
